import React, { useEffect, useState, Suspense, lazy } from "react";
import { useLocation } from "react-router-dom";
import { api } from "../api/api.js";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Helmet } from 'react-helmet-async';
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";

const BlogCard = lazy(() => import("../components/BlogCard.jsx"));

const categories = [
  { fa: "ماشینکاری", en: "machining" },
  { fa: "ورق‌کاری", en: "sheet" },
  { fa: "پرینت سه بعدی", en: "3d-printing" },
  { fa: "پرداخت سطوح", en: "finishes" },
  { fa: "انتخاب مواد", en: "materials" },
  { fa: "قطعات صنعتی", en: "industrial-parts" },
];

const Home = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const cat = new URLSearchParams(location.search).get('cat'); // Get category from query string

  // This useEffect hook is responsible for fetching posts data from the API endpoint when cat changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/api/posts${cat ? `?cat=${cat}` : ''}`);
        const filteredPosts = res.data.filter(post => post.draft === 0);
        setPosts(filteredPosts.reverse());
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    fetchData();
  }, [cat]);

  const groupedPosts = categories.map(category => ({
    ...category,
    posts: posts.filter(post => post.cat === category.en),
  }));

  return (
    <>
      <Helmet>
        <title>تولی هاب، پلی برای تحقق ایده‌های ناب</title>
        <meta
          name="description"
          content="وبسایتی برای علاقه‌مندان و متخصصین فعال در حوزه های مختلف ساخت و تولید و خرید تجهیزات صنعتی"
        />
        <link rel="canonical" href="https://www.tolihub.ir" />
      </Helmet>
      <Box className="home" p={2}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <CircularProgress />
          </Box>
        ) : (
          groupedPosts.map(group => (
            group.posts.length > 0 && (
              <Box key={group.en} mb={4}>
                <Typography 
                  variant="h4" 
                  gutterBottom
                  sx={{
                    marginBottom: 3,
                    padding: 2,
                    borderRadius: 2,
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {group.fa}
                </Typography>
                <Divider/>
                <Grid container spacing={3} sx={{ margin: 2, padding: 2 }}>
                  {group.posts.map((post) => (
                    <Grid item xs={12} sm={6} md={4} key={post.id}>
                      <Suspense fallback={<CircularProgress />}>
                        <Box sx={{ width: '100%', height: '100%' }}> {/* Explicit dimensions */}
                          <BlogCard post={post} />
                        </Box>
                      </Suspense>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )
          ))
        )}
      </Box>
    </>
  );
};

export default Home;