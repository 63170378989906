import React, { useContext } from "react";
import Logo from "../img/Logo.avif";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import Button from "@mui/material/Button";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import CreateIcon from "@mui/icons-material/Create";
import DashboardIcon from "@mui/icons-material/Dashboard";

const Navbar = () => {
  const { currentUser, logout } = useContext(AuthContext);

  const pages = [
    { fa: "ماشینکاری", en: "machining" },
    { fa: "ورق‌کاری", en: "sheet" },
    { fa: "پرینت سه بعدی", en: "3d-printing" },
    { fa: "پرداخت سطوح", en: "finishes" },
    { fa: "انتخاب مواد", en: "materials" },
    { fa: "قطعات صنعتی", en: "industrial-parts" },
  ];

  const settings = [currentUser?.username, "داشبورد"];
  const settinglists = ["account", "dashboard"];

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
 const avatarUrl = `../../upload/avatars/${currentUser?.img}`;

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <div className="Navbar">
      <AppBar
        className="Appbar"
        sx={{
          backgroundColor: "white",
          color: "#196845",
          boxShadow: "none", // Remove the default shadow
          borderBottom: "2px solid #4c4c4c", // Add a line under the AppBar
        }}
        position="static"
      >
        <Box>
          <img
            src={Logo}
            alt="Logo"
            style={{
              height: "100%",
              maxWidth: 160,
              width: "auto",
              objectFit: "cover",
            }}
          />
        </Box>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 3,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              ToliHub
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.en} onClick={handleCloseNavMenu}>
                    <Link
                      className="link"
                      to={`/?cat=${page.en}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Typography textAlign="center">{page.fa}</Typography>
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              ToliHub
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <Link className="link" to={`/?cat=${page.en}`} key={page.en}>
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: "#4c4c4c", display: "block" }}
                  >
                    {page.fa}
                  </Button>
                </Link>
              ))}
            </Box>

            {currentUser ? (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt={currentUser.username}
                      src = {avatarUrl}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem
                    key={currentUser?.username}
                    onClick={handleCloseUserMenu}
                  >
                    <Typography color="secondary">
                      {currentUser?.username}
                    </Typography>
                  </MenuItem>
                  <MenuItem key="داشبورد" onClick={handleCloseUserMenu}>
                    <Link to="/dashboard">
                      <Button
                        color="secondary"
                        startIcon={<DashboardIcon />}
                        sx={{
                          textTransform: "none",
                          "& .MuiButton-startIcon": {
                            marginLeft: 1,
                            marginRight: 1,
                          },
                        }}
                      >
                        داشبورد
                      </Button>
                    </Link>
                  </MenuItem>
                  <Link to="/Write">
                    <MenuItem key="محتوا" onClick={handleCloseUserMenu}>
                      <Button
                        startIcon={<CreateIcon />}
                        color="secondary"
                        sx={{
                          textTransform: "none",
                          "& .MuiButton-startIcon": {
                            marginLeft: 1,
                            marginRight: 1,
                          },
                        }}
                      >
                        تولید محتوا
                      </Button>
                    </MenuItem>
                  </Link>
                  <MenuItem key="خروج" onClick={handleCloseUserMenu}>
                    <Button
                      onClick={logout}
                      startIcon={<LogoutIcon />}
                      color="secondary"
                      sx={{
                        textTransform: "none",
                        "& .MuiButton-startIcon": {
                          marginLeft: 1,
                          marginRight: 1,
                        },
                      }}
                    >
                      <span>خروج&nbsp;&nbsp;</span>
                    </Button>
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <Link className="link" to="/login">
                <Button endIcon={<LoginIcon />} color="secondary" size="large">
                  ورود&nbsp;&nbsp;
                </Button>
              </Link>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default Navbar;
